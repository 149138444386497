export default (context) => {
  // let STAGGERED_ROLLOUT = 'MCM001,NTN001,JCM008,CLV001,KNX015,KNXO20,MNT009,CDL001,CDR001,WRG003,NSH015,ATL047'
//   let STAGGERED_ROLLOUT = process.env.STAGGERED_ROLLOUT;
    let STAGGERED_ROLLOUT = 'JCM004,ATL037,MNT006,MBL008,MFS019,MAC008,SAV007,MAR015,JCM002,PHC001,CHN010,ALB005,MBL012,PMS001,ATL043,MAC007,JCM009,BGK002,ATL067,ORT001,MNT011,SAV004,NSH022,MBL003,RME002,RME003,NSH013,TIF001,MAC013,SAV010,JCM006,MCM001,MAC003,VAL002,ALB002,ALB004,MAC010,MTN002,SAV009,MBL005,COL009,MNT001,ATL065,MAC009,KNX020,MBL001,MNT003,AUB003,KNX015,MBL002,BIR016,CAL001,GPM002,MNT009,MAC012,MNT005,SCA002,CDL001,CDR001,WRG003,MBL009,KIM001,NTN001,JCM007,MFS026,GVL001,KNX014,MFS020,ATL021,NSH020,KNX013,ATL008,ATL012,MFS012,ATL042,MAR024,MFS007,MFS011,MFS005,MFS016,ATL031,MFS021,ATL028,ATL057,ATL007,NSH026,MFS009,CTN002,MFS025,COL007,KNX018,MNT006,ATL051,ATL010,ATL026,ATL066,COL001,ROS002,ATL002,ATL037,NSH016,JCM004,CBT001,CTV001,ALB001,MAC004,COL006,CHN016,COL010,JTN001,SAV005,CHN002,GRF001,WRG001,SAV008,NSH004,SAV002,MBL008,MFS024,COL005,HTB001,MFS027,Atlf02,Atlf07,Atlf18,Atlf22,Atlf24,Atlf32,Atlf33,Atlf34,ATLF21'

  if (STAGGERED_ROLLOUT) {
    let staggered_locations_arr = STAGGERED_ROLLOUT.split(",");
    console.log(
      "context.store.state.location:::: ",
      context.store.state.location
    );
    
    if (staggered_locations_arr && context.store.state.location) {
      if (staggered_locations_arr.includes(context.store.state.location.name)) {
        console.log("we have a staggered location!!!!!!!!!!!!!!!!!!!");

        // Obține Client ID și Session ID
        const getGtagInfo = () => {
          return new Promise((resolve) => {
            gtag("get", "G-GTZPK267QX", "client_id", (client_id) => {
              gtag("get", "G-GTZPK267QX", "session_id", (session_id) => {
                resolve({ clientId: client_id, sessionId: session_id });
              });
            });
          });
        };

        // Redirectare pe baza ID-urilor obținute
        getGtagInfo().then(({ clientId, sessionId }) => {
          if (
            context.route &&
            context.route.path.toString().includes("/order")
          ) {
            if (context.route.path.toString().includes("/product")) {
              if (context.store.state.location) {
                console.log(
                  "store.state.location.slug::: ",
                  context.store.state.location.slug
                );
                if (context.store.state.location.slug) {
                  return context.redirect(
                    `${context.store.state.location.slug}products/${context.route.query?.id}/?stay=y&clientId=${clientId}&sessionId=${sessionId}`
                  );
                }
              }
            } else {
              if (
                context.store.state.location &&
                context.store.state.location.slug
              ) {
                return context.redirect(
                  `${context.store.state.location.slug}?stay=y&clientId=${clientId}&sessionId=${sessionId}`
                );
              } else {
                return context.redirect("/");
              }
            }
          }

          if (
            context.route &&
            context.route.path.toString().includes("/account")
          ) {
            console.log("we are in an account route");
            if (process.browser && window) {
              console.log("process.browser:::: ", process.browser);
              window.onNuxtReady(() => {
                window.$nuxt.$router.push("/");
              });
            } else {
              console.log("no process browser here");
              return context.redirect(302, "/");
            }
          }

          if (
            context.route &&
            context.route.path.toString().includes("/checkout")
          ) {
            console.log("we are in a checkout route");
            if (process.browser && window) {
              window.onNuxtReady(() => {
                window.$nuxt.$router.push("/");
              });
            } else {
              return context.redirect(302, "/");
            }
          }
        });
      } else {
        console.log("just a normal boring location!");
      }
    }
  }
};
